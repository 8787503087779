.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}

.loader-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: all;
  z-index: 10001;
}

.loader-body {
  width: 400px;
  height: 300px;
  background-color: #f0f1f4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-input {
  border: 1px solid #000000;
  border-radius: 5px;
}

.password-toggle-icon {
  position: absolute;
  top: 5px;
  z-index: 1000;
  cursor: pointer;
}

.toggle-icon {
  cursor: pointer;
}

.invalid-text {
  margin-top: 0.25em;
  font-size: 0.875em;
}

.invalid {
  border: 1px solid red;
}

html:not([dir=rtl]) .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating)  {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px
}