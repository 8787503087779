.switch-btn-register {
  border-radius: 20px;
  z-index: 99;
  position: relative;
  left: 10px;
}

.switch-btn-login {
  border-radius: 20px;
  color: #F3F3F3;
  position: relative;
  right: 15px;
  width: 100%
}

