.preview-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.preview-body {
  display: inline-flex;
  border-radius: 2;
  border: '1px solid #eaeaea';
  margin-bottom: 5px;
  margin-right: 8px;
  padding: 4px;
  box-sizing: border-box;
}

.preview-inner {
  display: flex;
  min-width: 0;
  overflow-x: hidden;
}

.preview-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}